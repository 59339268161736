/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable consistent-return */
import Web3 from "web3";
import axios from "axios";
import { dealApi } from "../api/api";

const web3 = new Web3(window.ethereum);

export function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

export const waitForTransactionReceipt = (txHash) => {
  return new Promise((resolve, reject) => {
    const checkReceipt = async () => {
      try {
        const receipt = await web3.eth.getTransactionReceipt(txHash);

        if (receipt && receipt.status === 1n) {
          resolve(receipt);
        } else {
          setTimeout(checkReceipt, 5000); // Check again in  5 seconds
        }
      } catch (error) {
        console.log("Error checking receipt! ", error);
        reject(error);
      }
    };
    checkReceipt();
  });
};

export const retryOperation = async (operation, delay, attempts) => {
  try {
    const result = await operation();
    if (result && result.status) {
      return result;
    }
    throw new Error("Transaction not confirmed yet");
  } catch (error) {
    if (attempts - 1 > 0) {
      console.log(
        `${error.message} Retrying... Attempts left: ${attempts - 1}`
      );
      await sleep(delay);
      return retryOperation(operation, delay, attempts - 1);
    }
    throw new Error(
      "Max attempts reached. Transaction might still be pending."
    );
  }
};

export const checkTransactionReceipt = async (transactionHash) => {
  try {
    const etherScanApiKey = "7CNSN2VXKMPT9WWT6QWVY59BM69DUD5FXZ";

    // Validate transactionHash here (check for its format, length, etc)
    if (!transactionHash) {
      throw new Error("Invalid transaction hash");
    }

    let responseData;

    // Define a function to make the API call
    const makeAPICall = async () => {
      const response = await axios.get(
        `https://api.polygonscan.com/api?module=proxy&action=eth_getTransactionReceipt&txhash=${transactionHash}&apikey=${etherScanApiKey}`
      );

      if (response.data.error) {
        throw new Error(response.data.error.message);
      }

      // Extract the relevant data from the response
      responseData = response.data;
    };

    // Set up an interval to check the API every 5 seconds
    const intervalId = setInterval(async () => {
      await makeAPICall();

      // Check if the desired condition is met (modify this condition as needed)
      if (responseData.result && responseData.result.status === "0x1") {
        // Handle the data as needed and return it
        clearInterval(intervalId); // Stop the interval
        return responseData;
      }
    }, 5000);
  } catch (error) {
    throw new Error(error.message);
  }
};

export const fetchAndCheckDealStatus = async (dealId, updateDeal) => {
  try {
    const dealResponse = await dealApi.getDeal(dealId);
    const { deal_status, service_status } = dealResponse.data;

    const commonConditions =
      deal_status === "outdated" ||
      service_status === "mediation" ||
      service_status === "rejected" ||
      service_status === "canceled";

    if (commonConditions) {
      updateDeal(dealResponse.data);
      return { isCommonConditionMet: true, newDeal: dealResponse.data };
    }

    return { isCommonConditionMet: false, newDeal: dealResponse.data };
  } catch (error) {
    console.error("Error fetching deal status:", error);
    return { isCommonConditionMet: true, newDeal: null };
  }
};

/**
 * Executes a blockchain transaction and handles the response.
 *
 * @param {Object} transactionMethod - The web3 method to call the blockchain transaction.
 * @param {String} dealId - The ID of the deal being processed.
 * @param {String} walletAddress - The address of the wallet initiating the transaction.
 * @param {Function} apiCall - The API call function to update the backend with the transaction details.
 * @param {String} apiKey - The API key for accessing the blockchain explorer API.
 * @param {Function} setIsLoading - Function to set the loading state.
 * @param {Function} setShowSuccessModal - Function to show the success modal.
 * @param {Function} setShowFailureModal - Function to show the failure modal.
 * @param {Function} setDealData - Function to update the deal data state.
 */

export const executeTransaction = async (
  transactionMethod,
  dealId,
  walletAddress,
  apiCall,
  apiKey,
  setIsLoading,
  setShowSuccessModal,
  setDealData,
  setShowFailureModal
) => {
  try {
    let transactionHash;
    let backResponseResult;
    let responseData;

    // Set the loading state to true
    setIsLoading(true);

    // Send the transaction and open MetaMask window for signing
    const sentTx = await transactionMethod
      .send({ from: walletAddress })
      .on("transactionHash", async (hash) => {
        transactionHash = hash;

        // Make the API call and wait for the response
        backResponseResult = await apiCall(dealId, walletAddress, hash);

        // Validate transaction hash
        if (!hash) {
          throw new Error("Invalid transaction hash");
        }

        // Function to make the API call to the blockchain explorer
        const makeAPICall = async () => {
          const response = await axios.get(
            `https://api.polygonscan.com/api?module=proxy&action=eth_getTransactionReceipt&txhash=${hash}&apikey=${apiKey}`
          );

          if (response.data.error) {
            throw new Error(response.data.error.message);
          }

          responseData = response.data;
        };

        // Set up an interval to check the API every 5 seconds
        const intervalId = setInterval(async () => {
          await makeAPICall();

          // Check if the transaction is successful and the backend call was successful
          if (
            responseData.result &&
            responseData.result.status === "0x1" &&
            backResponseResult.status === 200
          ) {
            // Clear the interval
            clearInterval(intervalId);

            // Set the loading state to false
            setIsLoading(false);

            // Show the success modal
            setShowSuccessModal(true);

            // Fetch the updated deal data
            const dealRes = await dealApi.getDeal(dealId);
            setDealData(dealRes.data);
          }
        }, 5000);
      });
  } catch (error) {
    console.error("Error in executing transaction:", error);

    // Set the loading state to false and show the failure modal
    setIsLoading(false);
    setShowFailureModal(true);
  }
};

export const getTokenDecimals = async (TokenSC) => {
  try {
    const decimals = await TokenSC.methods.decimals().call();
    return decimals;
  } catch (error) {
    console.error("Error fetching token decimals:", error);
    return null;
  }
};

export const getAndConvertBalance = async (walletAddress, TokenSC) => {
  try {
    const balanceInWei = await TokenSC.methods.balanceOf(walletAddress).call();
    const tokenDecimals = await getTokenDecimals(TokenSC);
    const tokenBalance = Number(balanceInWei) / 10 ** Number(tokenDecimals);

    return tokenBalance;
  } catch (error) {
    console.error("Error converting token balance:", error);
    return null;
  }
};
